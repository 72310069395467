.Selection {
  height: 290px;
}

.specSelection .Icon,
.raceSelection .Icon,
.factionSelection .Icon {
  margin-left: 2px;
  margin-right: 2px;
}

.specSelection {
  width: 44.445%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.raceSelection {
  width: 33.335%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.factionSelection {
  width: 22.22%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.btn {
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 0;
  margin-right: 0px;
  cursor: pointer;
}
.spanLink {
  cursor: pointer;
}

.stageSelectionGroup {
  width: 100%;
}
