.Item {
  height: 56px;
  width: 56px;
  margin-bottom: 6px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  cursor: pointer;
}

.BisListFingers > .Item,
.BisListTrinkets > .Item,
.BissListBottom > .Item {
  margin-right: 6px;
}

.Item > div {
  float: left;
}

.Selection {
  height: 290px;
}

.specSelection .Icon,
.raceSelection .Icon,
.factionSelection .Icon {
  margin-left: 2px;
  margin-right: 2px;
}

.specSelection {
  width: 44.445%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.raceSelection {
  width: 33.335%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.factionSelection {
  width: 22.22%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.btn {
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 0;
  margin-right: 0px;
  cursor: pointer;
}
.spanLink {
  cursor: pointer;
}

.stageSelectionGroup {
  width: 100%;
}

.Icon {
  height: 56px;
  width: 56px;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
}

.selected {
  border: 1px outset #ff8000;
  box-shadow: inset 0 0 4px #ff8000;
}

@font-face {
  font-family: "FrizQuadrataBold";
  src: url("/font/FrizQuadrataBold.eot");
  src: url("/font/FrizQuadrataBold.eot?#iefix") format("embedded-opentype"),
    url("/font/FrizQuadrataBold.svg#FrizQuadrataBold") format("svg"),
    url("/font/FrizQuadrataBold.ttf") format("truetype"),
    url("/font/FrizQuadrataBold.woff") format("woff"),
    url("/font/FrizQuadrataBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

#videoslider {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.classicLogo {
  width: 20%;
  height: 20%;
}

.discordLogo {
  height: 25px;
  margin-bottom: 10px;
}

html {
  background-color: black;
  min-width: 600px;
}

body {
  background-color: black;
}
#root {
  max-width: 1400px;
  margin: 0 auto;
}

.App {
  font-family: FrizQuadrataBold;
  text-align: center;
  background-color: black;
  color: white;
}

.BisListFingers > div,
.BisListTrinkets > div,
.BisListLeft,
.BisListCenter,
.BisListRight,
.BissListBottom > div,
.row {
  display: inline-block;
}
.BisListFingers,
.BisListTrinkets {
  max-height: 62px;
}

.BisListCenter {
  padding: 5px;
  width: 320px;
  visibility: visible;
  vertical-align: top;
}

.Banner {
  padding: 10px;
}

.Footer {
  font-family: "Helvetica";
}

.qe0 {
  border: 1px outset #9d9d9d;
  box-shadow: inset 0 0 4px #9d9d9d;
}
.qe1 {
  border: 1px outset #fff;
  box-shadow: inset 0 0 4px #fff;
}
.qe2 {
  border: 1px outset #1eff00;
  box-shadow: inset 0 0 4px #1eff00;
}
.qe3 {
  border: 1px outset #0070ff;
  box-shadow: inset 0 0 4px #0070ff;
}
.qe4 {
  border: 1px outset #a335ee;
  box-shadow: inset 0 0 4px #a335ee;
}
.qe5 {
  border: 1px outset #ff8000;
  box-shadow: inset 0 0 4px #ff8000;
}

.tooltip-visible {
  visibility: visible !important;
}

.wowhead-tooltip-screen-close,
.wowhead-touch-tooltip-closer {
  background: #933;
  border-radius: 99px;
  color: #fff;
  font-weight: bold;
  height: 39px;
  line-height: 39px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 4px;
  width: 39px;
  z-index: 3;
}
.wowhead-tooltip-screen-close:before,
.wowhead-tooltip-screen-close:after,
.wowhead-touch-tooltip-closer:before,
.wowhead-touch-tooltip-closer:after {
  background: #fff;
  content: " ";
  display: block;
  height: 3px;
  left: 10px;
  position: absolute;
  top: 18px;
  width: 19px;
}
.wowhead-tooltip-screen-close:before,
.wowhead-touch-tooltip-closer:before {
  transform: rotate(45deg);
}
.wowhead-tooltip-screen-close:after,
.wowhead-touch-tooltip-closer:after {
  transform: rotate(-45deg);
}
.wowhead-tooltip-screen {
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  box-shadow: inset 0 0 200px #000;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999999;
}
.wowhead-tooltip-screen-inner-wrapper {
  bottom: 60px;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.wowhead-tooltip-screen-inner {
  box-sizing: border-box;
  min-height: 100%;
  padding: 45px 5px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.wowhead-tooltip-screen-inner .wowhead-tooltip {
  display: none;
  margin: 0 auto;
  max-width: 320px;
  position: static !important;
  width: auto !important;
}
.wowhead-tooltip-screen-inner .wowhead-tooltip[data-visible="yes"] {
  display: block;
}
.wowhead-tooltip-screen-inner
  .wowhead-tooltip
  > table
  > tbody
  > tr
  > td
  > table {
  white-space: normal !important;
}
.wowhead-tooltip-screen-inner .wowhead-tooltip .screenshot {
  background-position: center;
  display: block;
  height: 250px;
  margin: 5px 2px;
  position: static;
  width: auto;
}
.wowhead-tooltip-screen-inner-box {
  position: relative;
}
.wowhead-tooltip-screen-inner-box
  .wowhead-tooltip
  .wowhead-touch-tooltip-closer {
  display: none;
}
.wowhead-tooltip-screen-inner-box .wowhead-tooltip p {
  left: -44px;
  top: -1px;
}
.wowhead-tooltip-screen-caption {
  bottom: 5px;
  font-size: 21px;
  font-weight: 600;
  left: 5px;
  position: absolute;
  right: 5px;
  z-index: 1;
}
.wowhead-tooltip-screen-caption a {
  background: #333;
  border-radius: 4px;
  color: #fff;
  display: block;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
.wowhead-tooltip-screen-caption a:first-child {
  float: left;
  width: 49%;
  width: calc(50% - 2.5px);
}
.wowhead-tooltip-screen-caption a:last-child {
  margin: 0 0 0 auto;
  width: 49%;
  width: calc(50% - 2.5px);
}
.wowhead-tooltip-screen-caption a:first-child:last-child {
  box-shadow: 0 0 100px #000;
  float: none;
  margin: 0;
  width: auto;
}
.wowhead-tooltip-screen-caption a .fa {
  margin-right: 5px;
}
@media screen and (max-width: 320px) {
  .wowhead-tooltip-screen-inner-wrapper {
    bottom: 0;
  }
  .wowhead-tooltip-screen-caption {
    bottom: auto;
    left: auto;
    right: 53px;
    top: 4px;
    z-index: 3;
  }
  .wowhead-tooltip-screen-caption a:first-child:last-child {
    background: #393;
    border-radius: 99px;
    height: 0 !important;
    line-height: 39px;
    overflow: hidden;
    padding: 39px 39px 0 0;
    width: 0 !important;
  }
  .wowhead-tooltip-screen-caption a .fa {
    display: block;
    left: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.wowhead-touch-tooltip-closer {
  height: 26px;
  right: 0;
  top: -14px;
  width: 26px;
}
.wowhead-touch-tooltip-closer:before,
.wowhead-touch-tooltip-closer:after {
  height: 2px;
  left: 7px;
  top: 12px;
  width: 12px;
}
@media screen and (max-width: 407px) {
  .wowhead-tooltip-screen-inner-box .wowhead-tooltip p {
    left: -1px;
    top: -44px;
  }
}
.wowhead-tooltip {
  max-width: 100%;
  overflow: visible !important;
  visibility: hidden;
  z-index: 100000006;
  border-radius: 6px;
}
.main-contents .wowhead-tooltip-width-restriction td:first-child > b {
  white-space: normal;
}
.wowhead-tooltip a {
  text-decoration: none;
}
.wowhead-tooltip a:hover {
  text-decoration: underline;
}
.wowhead-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  width: auto;
}
.wowhead-tooltip td {
  max-width: 500px;
}
.wowhead-tooltip td td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.layout .main-contents .wowhead-tooltip td {
  overflow: auto;
  text-overflow: clip;
}
@media screen and (max-width: 599px) {
  .wowhead-tooltip table,
  .wowhead-tooltip th,
  .wowhead-tooltip td {
    white-space: normal !important;
  }
}
.wowhead-tooltip table,
.wowhead-tooltip td,
.wowhead-tooltip th,
.wowhead-tooltip tbody {
  border: 0 !important;
}
.wowhead-tooltip td table td table {
  width: 99.5%;
}
.wowhead-tooltip td,
.wowhead-tooltip th {
  background: url(/images/wow/tooltip.png);
  font-family: Verdana, "Open Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  font-size: 12px;
  line-height: 17px;
  color: #fff;
}
.wowhead-tooltip th {
  padding: 3px;
  vertical-align: top;
}
.wowhead-tooltip td {
  padding: 8px 4px 1px 9px;
  text-align: left;
  vertical-align: top;
}
.wowhead-tooltip b {
  color: inherit !important;
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
}
.wowhead-tooltip div.indent {
  padding-left: 0.6em;
}
.wowhead-tooltip td th,
.wowhead-tooltip td td {
  background: none;
}
.wowhead-tooltip td th {
  padding: 0 0 0 4em;
  text-align: right;
  font-weight: normal;
}
.wowhead-tooltip td td {
  padding: 0;
  text-align: left;
}
.wowhead-tooltip td blockquote {
  margin: 0 10px;
}
.wowhead-tooltip p {
  position: absolute;
  left: -44px;
  top: -1px;
  width: 44px;
  height: 44px;
  background: 4px 4px no-repeat;
  margin: 0;
  padding: 0;
}
.wowhead-tooltip p div {
  width: 44px;
  height: 44px;
  background-image: url(/images/Icon/medium/border/default.png);
}
.wowhead-tooltip table.shrink b {
  font-size: 12px;
  line-height: 15px;
}
.wowhead-tooltip table.shrink td,
.wowhead-tooltip table.shrink th {
  font-size: 10px;
  line-height: 14px;
}
.wowhead-tooltip .mapper {
  margin: 2px 0 3px 1px;
  border: none;
}
.wowhead-tooltip ins {
  border-bottom: 1px dotted;
  color: #5df644;
  text-decoration: none;
}
.wowhead-tooltip del {
  border-bottom: 1px dotted;
  color: #ff8040;
  text-decoration: none;
}
.wowhead-tooltip .wowhead-tooltip-requirements {
  white-space: normal;
}
.wowhead-tooltip .whtt-transmogrified-to {
  color: #ff91ff;
}
.wowhead-tooltip .screenshot {
  display: inline-block;
  position: absolute;
  right: -300px;
  width: 300px;
  top: 0;
  height: 100%;
  min-height: 250px;
  background: no-repeat top left;
  background-size: contain;
}
@media screen and (max-width: 499px) {
  #main .wowhead-tooltip b {
    white-space: normal;
  }
}
@media screen and (max-width: 339px) {
  #main .wowhead-tooltip {
    max-width: 100%;
  }
}
.wowhead-tooltip-item-classes,
.wowhead-tooltip-item-races {
  white-space: normal;
}
.wowhead-tooltip-npc-graphic td {
  overflow: visible !important;
}
.wowhead-tooltip-npc-graphic td > div {
  pointer-events: none;
  position: relative;
  text-align: center;
  top: -3px;
}
.wowhead-tooltip-npc-graphic td > div img {
  display: block;
  margin: -64px auto 0;
}
.wowhead-follower-container {
  width: 256px;
}
.wowhead-follower-container.abilities > div {
  margin: 4px 0;
}
.wowhead-follower-container.abilities img.ability {
  vertical-align: top;
  margin: 2px 2px 0 0;
}
.wowhead-follower-container.abilities div.ability {
  display: inline-block;
  width: 235px;
}
.wowhead-follower-container.abilities .description {
  font-size: 10px;
  line-height: 14px;
}
.wowhead-follower-container.abilities img {
  width: 15px;
  height: 15px;
}
.wowhead-follower-container.abilities img.threat {
  vertical-align: middle;
  padding: 4px;
  background: url(/images/Icon/small/border/garrisonability.png) -2px -2px/27px
    27px no-repeat;
}
.wowhead-follower-container.follower-header {
  position: relative;
  width: 192px;
  top: -3px;
  left: -6px;
  margin-right: -6px;
  padding-left: 64px;
  min-height: 64px;
  margin-top: 12px;
}
.wowhead-follower-container:first-child {
  margin-top: 0;
}
.wowhead-follower-header .name {
  margin-bottom: 5px;
}
.wowhead-follower {
  height: 64px;
  width: 64px;
  background: url(/images/garrisons/garrison-mission-sprite.png?1) 0 -136px;
}
.wowhead-follower-container .wowhead-follower {
  position: absolute;
  top: 0;
  left: 0;
}
.wowhead-follower.class-image[data-class] {
  background: url(/images/garrisons/GarrisonFollowerClassIcons.png);
  display: block;
  height: 49px;
  opacity: 0.25;
  position: absolute;
  left: 206px;
  top: 0;
  width: 55px;
}
.wowhead-follower.class-image[data-class="1"] {
  background-position: -58px -1px;
}
.wowhead-follower.class-image[data-class="2"] {
  background-position: -115px -52px;
}
.wowhead-follower.class-image[data-class="3"] {
  background-position: -1px -1px;
}
.wowhead-follower.class-image[data-class="4"] {
  background-position: -1px -52px;
}
.wowhead-follower.class-image[data-class="5"] {
  background-position: -115px -1px;
}
.wowhead-follower.class-image[data-class="6"] {
  background-position: -172px -52px;
}
.wowhead-follower.class-image[data-class="7"] {
  background-position: -1px -205px;
}
.wowhead-follower.class-image[data-class="8"] {
  background-position: -1px -103px;
}
.wowhead-follower.class-image[data-class="9"] {
  background-position: -1px -154px;
}
.wowhead-follower.class-image[data-class="10"] {
  background-position: -172px -1px;
}
.wowhead-follower.class-image[data-class="11"] {
  background-position: -58px -52px;
}
.wowhead-follower[data-quality] .avatar-portrait:before {
  background: url(/images/garrisons/garrison-mission-sprite.png?1) -64px -136px;
  content: " ";
  display: block;
  height: 64px;
  left: -13px;
  position: absolute;
  top: -12px;
  width: 64px;
  z-index: 2;
}
.wowhead-follower[data-quality="2"] .avatar-portrait:before {
  background-position: -128px -72px;
}
.wowhead-follower[data-quality="3"] .avatar-portrait:before {
  background-position: -128px -136px;
}
.wowhead-follower[data-quality="4"] .avatar-portrait:before {
  background-position: -128px -200px;
}
.wowhead-follower[data-quality="5"] .avatar-portrait:before {
  background-position: -128px -264px;
}
.wowhead-follower .avatar-portrait {
  background-size: 40px 40px;
  background-position: top;
  height: 35px;
  left: 12px;
  position: absolute;
  top: 9px;
  width: 40px;
  z-index: 1;
}
.wowhead-follower .avatar-level {
  height: 28px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 40px;
  width: 64px;
}
.wowhead-follower[data-quality] .avatar-level:before {
  background: url(/images/garrisons/garrison-mission-sprite.png?1) 0 -264px;
  content: " ";
  display: block;
  height: 32px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 64px;
  z-index: 3;
}
.wowhead-follower[data-quality="2"] .avatar-level:before {
  background-position: -64px -264px;
}
.wowhead-follower[data-quality="3"] .avatar-level:before {
  background-position: 0 -296px;
}
.wowhead-follower[data-quality="4"] .avatar-level:before {
  background-position: -64px -296px;
}
.wowhead-follower[data-quality="5"] .avatar-level:before {
  background-position: 0 -328px;
}
.wowhead-follower[data-quality] .avatar-level:after {
  content: attr(data-level);
  display: block;
  font-size: 11px;
  left: 0;
  line-height: 28px;
  position: absolute;
  top: 0;
  width: 64px;
  z-index: 4;
}
.wowhead-tooltip-powered {
  background: url(/images/logos/wh-logo-54.png) left bottom no-repeat;
  display: none;
  font-size: 8px;
  left: 100%;
  left: calc(100% + 3px);
  min-width: 54px;
  padding: 0 0 43px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 2px;
  white-space: nowrap;
}
@media (-webkit-min-device-pixel-ratio: 1.25), (-webkit-min-device-pixel-ratio: 0.9375), (min-resolution: 90dpi) {
  .wowhead-tooltip-powered {
    background-image: url(/images/logos/wh-logo-81.png);
    background-size: 54px auto;
  }
}
.wowhead-tooltip-inline .wowhead-tooltip {
  display: inline-block;
  position: relative;
}
.wowhead-tooltip-inline-icon .wowhead-tooltip {
  margin-left: 44px;
}
.moneygold,
.moneysilver,
.moneycopper,
.moneyitem,
.moneyachievement,
.moneysocketmeta,
.moneysocketred,
.moneysocketyellow,
.moneysocketblue,
.moneysocketprismatic {
  background: no-repeat right center;
  color: #fff;
  display: inline-block;
}
.wowhead-tooltip .moneygold,
.wowhead-tooltip .moneysilver,
.wowhead-tooltip .moneycopper,
.wowhead-tooltip .moneyitem,
.wowhead-tooltip .moneyachievement,
.wowhead-tooltip .moneysocketmeta,
.wowhead-tooltip .moneysocketred,
.wowhead-tooltip .moneysocketyellow,
.wowhead-tooltip .moneysocketblue,
.wowhead-tooltip .moneysocketprismatic {
  color: #fff;
}
.moneyitem {
  padding-right: 18px;
}
.moneygold {
  padding-right: 15px;
  background-image: url("https://wow.zamimg.com/images/icons/money-gold.gif");
}
.moneysilver {
  padding-right: 15px;
  background-image: url("https://wow.zamimg.com/images/icons/money-silver.gif");
}
.moneycopper {
  padding-right: 15px;
  background-image: url("https://wow.zamimg.com/images/icons/money-copper.gif");
}
.moneyachievement {
  padding-right: 13px;
  background-image: url(/images/icons/achievement.gif);
}
.moneysocketmeta {
  padding-right: 18px;
  background-image: url(/images/icons/socket-meta.gif);
}
.moneysocketred {
  padding-right: 18px;
  background-image: url(/images/icons/socket-red.gif);
}
.moneysocketyellow {
  padding-right: 18px;
  background-image: url(/images/icons/socket-yellow.gif);
}
.moneysocketblue {
  padding-right: 18px;
  background-image: url(/images/icons/socket-blue.gif);
}
.moneysocketprismatic {
  padding-right: 18px;
  background-image: url(/images/icons/socket-prismatic.gif);
}
a.moneyitem,
a.moneyachievement,
a.moneysocketmeta,
a.moneysocketred,
a.moneysocketyellow,
a.moneysocketblue,
a.moneysocketprismatic {
  border-bottom: 1px solid transparent;
  color: #fff !important;
  text-decoration: none !important;
}
.wowhead-tooltip a.moneyitem,
.wowhead-tooltip a.moneyachievement,
.wowhead-tooltip a.moneysocketmeta,
.wowhead-tooltip a.moneysocketred,
.wowhead-tooltip a.moneysocketyellow,
.wowhead-tooltip a.moneysocketblue,
.wowhead-tooltip a.moneysocketprismatic {
  color: #fff !important;
}
.wowhead-tooltip a.moneyitem:hover,
.wowhead-tooltip a.moneyachievement:hover,
.wowhead-tooltip a.moneysocketmeta:hover,
.wowhead-tooltip a.moneysocketred:hover,
.wowhead-tooltip a.moneysocketyellow:hover,
.wowhead-tooltip a.moneysocketblue:hover,
.wowhead-tooltip a.moneysocketprismatic:hover {
  border-bottom: 1px solid #fff;
}
a.moneyitem:hover,
a.moneyachievement:hover,
a.moneysocketmeta:hover,
a.moneysocketred:hover,
a.moneysocketyellow:hover,
a.moneysocketblue:hover,
a.moneysocketprismatic:hover {
  border-bottom: 1px solid #fff;
}
a span.moneyitem,
a span.moneyachievement,
a span.moneysocketmeta,
a span.moneysocketred,
a span.moneysocketyellow,
a span.moneysocketblue,
a span.moneysocketprismatic {
  color: #fff;
}
.wowhead-tooltip a span.moneyitem,
.wowhead-tooltip a span.moneyachievement,
.wowhead-tooltip a span.moneysocketmeta,
.wowhead-tooltip a span.moneysocketred,
.wowhead-tooltip a span.moneysocketyellow,
.wowhead-tooltip a span.moneysocketblue,
.wowhead-tooltip a span.moneysocketprismatic {
  color: #fff;
}
.blizzard-blue {
  color: #00c0ff !important;
}
.fanbyte-apricot {
  color: #ea4c41 !important;
}
.wowhead-red {
  color: #a71a19 !important;
}
.ex7,
.ex7 a {
  color: #7194d1 !important;
}
.q,
.q a,
.color-q,
.wowhead-tooltip .q a {
  color: #ffd100 !important;
}
.q-alt,
.q-alt a {
  color: #ffd100 !important;
}
.has-content .q-alt,
.has-content.q-alt {
  color: #e5c017 !important;
}
.q0,
.q0 a,
.color-q0,
.wowhead-tooltip .q0 a {
  color: #9d9d9d !important;
}
.q0-alt,
.q0-alt a {
  color: #9d9d9d !important;
}
.has-content .q0-alt,
.has-content.q0-alt {
  color: #777 !important;
}
.q1,
.q1 a,
.color-q1,
.wowhead-tooltip .q1 a {
  color: #fff !important;
}
.q1-alt,
.q1-alt a {
  color: #fff !important;
}
.has-content .q1-alt,
.has-content.q1-alt {
  color: #000 !important;
}
.q2,
.q2 a,
.color-q2,
.wowhead-tooltip .q2 a {
  color: #1eff00 !important;
}
.q2-alt,
.q2-alt a {
  color: #1eff00 !important;
}
.has-content .q2-alt,
.has-content.q2-alt {
  color: #0b0 !important;
}
.q3,
.q3 a,
.color-q3,
.wowhead-tooltip .q3 a {
  color: #0070dd !important;
}
.q3-alt,
.q3-alt a {
  color: #0070dd !important;
}
.has-content .q3-alt,
.has-content.q3-alt {
  color: #0070dd !important;
}
.q4,
.q4 a,
.color-q4,
.wowhead-tooltip .q4 a {
  color: #a335ee !important;
}
.q4-alt,
.q4-alt a {
  color: #9345ff !important;
}
.has-content .q4-alt,
.has-content.q4-alt {
  color: #a335ee !important;
}
.q5,
.q5 a,
.color-q5,
.wowhead-tooltip .q5 a {
  color: #ff8000 !important;
}
.q5-alt,
.q5-alt a {
  color: #ff8000 !important;
}
.has-content .q5-alt,
.has-content.q5-alt {
  color: #ff8000 !important;
}
.q6,
.q6 a,
.color-q6,
.wowhead-tooltip .q6 a {
  color: #e5cc80 !important;
}
.q6-alt,
.q6-alt a {
  color: #e5cc80 !important;
}
.has-content .q6-alt,
.has-content.q6-alt {
  color: #998856 !important;
}
.q7,
.q7 a,
.color-q7,
.wowhead-tooltip .q7 a {
  color: #0cf !important;
}
.q7-alt,
.q7-alt a {
  color: #0cf !important;
}
.has-content .q7-alt,
.has-content.q7-alt {
  color: #079 !important;
}
.q8,
.q8 a,
.color-q8,
.wowhead-tooltip .q8 a {
  color: #0cf !important;
}
.q8-alt,
.q8-alt a {
  color: #0cf !important;
}
.has-content .q8-alt,
.has-content.q8-alt {
  color: #079 !important;
}
.q9,
.q9 a,
.color-q9,
.wowhead-tooltip .q9 a {
  color: #71d5ff !important;
}
.q9-alt,
.q9-alt a {
  color: #71d5ff !important;
}
.has-content .q9-alt,
.has-content.q9-alt {
  color: #4f95b3 !important;
}
.q10,
.q10 a,
.color-q10,
.wowhead-tooltip .q10 a {
  color: #ff4040 !important;
}
.q10-alt,
.q10-alt a {
  color: #ff4040 !important;
}
.has-content .q10-alt,
.has-content.q10-alt {
  color: #ff4040 !important;
}
.q13,
.q13 a,
.color-q13,
.wowhead-tooltip .q13 a {
  color: #ffff98 !important;
}
.q13-alt,
.q13-alt a {
  color: #ffff98 !important;
}
.has-content .q13-alt,
.has-content.q13-alt {
  color: #99995c !important;
}
.wowhead-tooltip .q,
.wowhead-tooltip .q a,
.wowhead-tooltip .color-q,
.wowhead-tooltip .wowhead-tooltip .q a {
  color: #ffd100 !important;
}
.wowhead-tooltip .q-alt,
.wowhead-tooltip .q-alt a {
  color: #ffd100 !important;
}
.wowhead-tooltip .q0,
.wowhead-tooltip .q0 a,
.wowhead-tooltip .color-q0,
.wowhead-tooltip .wowhead-tooltip .q0 a {
  color: #9d9d9d !important;
}
.wowhead-tooltip .q0-alt,
.wowhead-tooltip .q0-alt a {
  color: #9d9d9d !important;
}
.wowhead-tooltip .q1,
.wowhead-tooltip .q1 a,
.wowhead-tooltip .color-q1,
.wowhead-tooltip .wowhead-tooltip .q1 a {
  color: #fff !important;
}
.wowhead-tooltip .q1-alt,
.wowhead-tooltip .q1-alt a {
  color: #fff !important;
}
.wowhead-tooltip .q2,
.wowhead-tooltip .q2 a,
.wowhead-tooltip .color-q2,
.wowhead-tooltip .wowhead-tooltip .q2 a {
  color: #1eff00 !important;
}
.wowhead-tooltip .q2-alt,
.wowhead-tooltip .q2-alt a {
  color: #1eff00 !important;
}
.wowhead-tooltip .q3,
.wowhead-tooltip .q3 a,
.wowhead-tooltip .color-q3,
.wowhead-tooltip .wowhead-tooltip .q3 a {
  color: #0070dd !important;
}
.wowhead-tooltip .q3-alt,
.wowhead-tooltip .q3-alt a {
  color: #0070dd !important;
}
.wowhead-tooltip .q4,
.wowhead-tooltip .q4 a,
.wowhead-tooltip .color-q4,
.wowhead-tooltip .wowhead-tooltip .q4 a {
  color: #a335ee !important;
}
.wowhead-tooltip .q4-alt,
.wowhead-tooltip .q4-alt a {
  color: #9345ff !important;
}
.wowhead-tooltip .q5,
.wowhead-tooltip .q5 a,
.wowhead-tooltip .color-q5,
.wowhead-tooltip .wowhead-tooltip .q5 a {
  color: #ff8000 !important;
}
.wowhead-tooltip .q5-alt,
.wowhead-tooltip .q5-alt a {
  color: #ff8000 !important;
}
.wowhead-tooltip .q6,
.wowhead-tooltip .q6 a,
.wowhead-tooltip .color-q6,
.wowhead-tooltip .wowhead-tooltip .q6 a {
  color: #e5cc80 !important;
}
.wowhead-tooltip .q6-alt,
.wowhead-tooltip .q6-alt a {
  color: #e5cc80 !important;
}
.wowhead-tooltip .q7,
.wowhead-tooltip .q7 a,
.wowhead-tooltip .color-q7,
.wowhead-tooltip .wowhead-tooltip .q7 a {
  color: #0cf !important;
}
.wowhead-tooltip .q7-alt,
.wowhead-tooltip .q7-alt a {
  color: #0cf !important;
}
.wowhead-tooltip .q8,
.wowhead-tooltip .q8 a,
.wowhead-tooltip .color-q8,
.wowhead-tooltip .wowhead-tooltip .q8 a {
  color: #0cf !important;
}
.wowhead-tooltip .q8-alt,
.wowhead-tooltip .q8-alt a {
  color: #0cf !important;
}
.wowhead-tooltip .q9,
.wowhead-tooltip .q9 a,
.wowhead-tooltip .color-q9,
.wowhead-tooltip .wowhead-tooltip .q9 a {
  color: #71d5ff !important;
}
.wowhead-tooltip .q9-alt,
.wowhead-tooltip .q9-alt a {
  color: #71d5ff !important;
}
.wowhead-tooltip .q10,
.wowhead-tooltip .q10 a,
.wowhead-tooltip .color-q10,
.wowhead-tooltip .wowhead-tooltip .q10 a {
  color: #ff4040 !important;
}
.wowhead-tooltip .q10-alt,
.wowhead-tooltip .q10-alt a {
  color: #ff4040 !important;
}
.wowhead-tooltip .q13,
.wowhead-tooltip .q13 a,
.wowhead-tooltip .color-q13,
.wowhead-tooltip .wowhead-tooltip .q13 a {
  color: #ffff98 !important;
}
.wowhead-tooltip .q13-alt,
.wowhead-tooltip .q13-alt a {
  color: #ffff98 !important;
}
.q12 {
  color: #bd5f00 !important;
}
.r1 {
  color: #ff8040 !important;
}
.r2 {
  color: #ff0 !important;
}
.r3 {
  color: #40bf40 !important;
}
.r4 {
  color: gray !important;
}
.c1,
.c1 a {
  color: #c69b6d !important;
}
.c1-alt,
.c1-alt a {
  color: #c69b6d !important;
}
.has-content .c1-alt,
.has-content.c1-alt {
  color: #997854 !important;
}
.c2,
.c2 a {
  color: #f48cba !important;
}
.c2-alt,
.c2-alt a {
  color: #f48cba !important;
}
.has-content .c2-alt,
.has-content.c2-alt {
  color: #cc749c !important;
}
.c3,
.c3 a {
  color: #aad372 !important;
}
.c3-alt,
.c3-alt a {
  color: #aad372 !important;
}
.has-content .c3-alt,
.has-content.c3-alt {
  color: #7c9953 !important;
}
.c4,
.c4 a {
  color: #fff468 !important;
}
.c4-alt,
.c4-alt a {
  color: #fff468 !important;
}
.has-content .c4-alt,
.has-content.c4-alt {
  color: #99933f !important;
}
.c5,
.c5 a {
  color: #fff !important;
}
.c5-alt,
.c5-alt a {
  color: #fff !important;
}
.has-content .c5-alt,
.has-content.c5-alt {
  color: gray !important;
}
.c6,
.c6 a {
  color: #c41e3b !important;
}
.c6-alt,
.c6-alt a {
  color: #f46 !important;
}
.has-content .c6-alt,
.has-content.c6-alt {
  color: #c41e3b !important;
}
.c7,
.c7 a {
  color: #2359ff !important;
}
.c7-alt,
.c7-alt a {
  color: #36f !important;
}
.has-content .c7-alt,
.has-content.c7-alt {
  color: #2359ff !important;
}
.c8,
.c8 a {
  color: #68ccef !important;
}
.c8-alt,
.c8-alt a {
  color: #68ccef !important;
}
.has-content .c8-alt,
.has-content.c8-alt {
  color: #4f98b3 !important;
}
.c9,
.c9 a {
  color: #9382c9 !important;
}
.c9-alt,
.c9-alt a {
  color: #9382c9 !important;
}
.has-content .c9-alt,
.has-content.c9-alt {
  color: #9382c9 !important;
}
.c10,
.c10 a {
  color: #008467 !important;
}
.c10-alt,
.c10-alt a {
  color: #008467 !important;
}
.has-content .c10-alt,
.has-content.c10-alt {
  color: #008467 !important;
}
.c11,
.c11 a {
  color: #ff7c0a !important;
}
.c11-alt,
.c11-alt a {
  color: #ff7c0a !important;
}
.has-content .c11-alt,
.has-content.c11-alt {
  color: #ff7c0a !important;
}
.c12,
.c12 a {
  color: #a330c9 !important;
}
.c12-alt,
.c12-alt a {
  color: #a330c9 !important;
}
.has-content .c12-alt,
.has-content.c12-alt {
  color: #a330c9 !important;
}
.wowhead-tooltip .c1,
.wowhead-tooltip .c1 a {
  color: #c69b6d !important;
}
.wowhead-tooltip .c1-alt,
.wowhead-tooltip .c1-alt a {
  color: #c69b6d !important;
}
.wowhead-tooltip .c2,
.wowhead-tooltip .c2 a {
  color: #f48cba !important;
}
.wowhead-tooltip .c2-alt,
.wowhead-tooltip .c2-alt a {
  color: #f48cba !important;
}
.wowhead-tooltip .c3,
.wowhead-tooltip .c3 a {
  color: #aad372 !important;
}
.wowhead-tooltip .c3-alt,
.wowhead-tooltip .c3-alt a {
  color: #aad372 !important;
}
.wowhead-tooltip .c4,
.wowhead-tooltip .c4 a {
  color: #fff468 !important;
}
.wowhead-tooltip .c4-alt,
.wowhead-tooltip .c4-alt a {
  color: #fff468 !important;
}
.wowhead-tooltip .c5,
.wowhead-tooltip .c5 a {
  color: #fff !important;
}
.wowhead-tooltip .c5-alt,
.wowhead-tooltip .c5-alt a {
  color: #fff !important;
}
.wowhead-tooltip .c6,
.wowhead-tooltip .c6 a {
  color: #c41e3b !important;
}
.wowhead-tooltip .c6-alt,
.wowhead-tooltip .c6-alt a {
  color: #c41e3b !important;
}
.wowhead-tooltip .c7,
.wowhead-tooltip .c7 a {
  color: #2359ff !important;
}
.wowhead-tooltip .c7-alt,
.wowhead-tooltip .c7-alt a {
  color: #2359ff !important;
}
.wowhead-tooltip .c8,
.wowhead-tooltip .c8 a {
  color: #68ccef !important;
}
.wowhead-tooltip .c8-alt,
.wowhead-tooltip .c8-alt a {
  color: #68ccef !important;
}
.wowhead-tooltip .c9,
.wowhead-tooltip .c9 a {
  color: #9382c9 !important;
}
.wowhead-tooltip .c9-alt,
.wowhead-tooltip .c9-alt a {
  color: #9382c9 !important;
}
.wowhead-tooltip .c10,
.wowhead-tooltip .c10 a {
  color: #008467 !important;
}
.wowhead-tooltip .c10-alt,
.wowhead-tooltip .c10-alt a {
  color: #008467 !important;
}
.wowhead-tooltip .c11,
.wowhead-tooltip .c11 a {
  color: #ff7c0a !important;
}
.wowhead-tooltip .c11-alt,
.wowhead-tooltip .c11-alt a {
  color: #ff7c0a !important;
}
.wowhead-tooltip .c12,
.wowhead-tooltip .c12 a {
  color: #a330c9 !important;
}
.wowhead-tooltip .c12-alt,
.wowhead-tooltip .c12-alt a {
  color: #a330c9 !important;
}
.gem1 {
  color: #9d9d9d !important;
}
.gem2 {
  color: #e60c0b !important;
}
.gem4 {
  color: #ffff35 !important;
}
.gem6 {
  color: #f48905 !important;
}
.gem8 {
  color: #295df1 !important;
}
.gem10 {
  color: #b957fc !important;
}
.gem12 {
  color: #22c516 !important;
}
.gem14 {
  color: #fff !important;
}
.s1 {
  color: #ff3f40 !important;
}
.s2 {
  color: #fffb9f !important;
}
.s3 {
  color: #ffb2eb !important;
}
.socket-meta {
  padding-left: 19px;
  background: url(/images/icons/socket-meta.gif) no-repeat left center;
}
.socket-red {
  padding-left: 19px;
  background: url(/images/icons/socket-red.gif) no-repeat left center;
}
.socket-yellow {
  padding-left: 19px;
  background: url(/images/icons/socket-yellow.gif) no-repeat left center;
}
.socket-blue {
  padding-left: 19px;
  background: url(/images/icons/socket-blue.gif) no-repeat left center;
}
.socket-prismatic {
  padding-left: 19px;
  background: url(/images/icons/socket-prismatic.gif) no-repeat left center;
}
.socket-hydraulic {
  padding-left: 19px;
  background: url(/images/icons/socket-hydraulic.gif) no-repeat left center;
}
.socket-cogwheel {
  padding-left: 19px;
  background: url(/images/icons/socket-cogwheel.gif) no-repeat left center;
}
.socket-relic-blood {
  padding-left: 19px;
  background: url(/images/icons/relic-blood.png) no-repeat left center;
}
.socket-relic-arcane {
  padding-left: 19px;
  background: url(/images/icons/relic-arcane.png) no-repeat left center;
}
.socket-relic-fel {
  padding-left: 19px;
  background: url(/images/icons/relic-fel.png) no-repeat left center;
}
.socket-relic-iron {
  padding-left: 19px;
  background: url(/images/icons/relic-iron.png) no-repeat left center;
}
.socket-relic-shadow {
  padding-left: 19px;
  background: url(/images/icons/relic-shadow.png) no-repeat left center;
}
.socket-relic-frost {
  padding-left: 19px;
  background: url(/images/icons/relic-frost.png) no-repeat left center;
}
.socket-relic-fire {
  padding-left: 19px;
  background: url(/images/icons/relic-fire.png) no-repeat left center;
}
.socket-relic-water {
  padding-left: 19px;
  background: url(/images/icons/relic-water.png) no-repeat left center;
}
.socket-relic-life {
  padding-left: 19px;
  background: url(/images/icons/relic-life.png) no-repeat left center;
}
.socket-relic-storm {
  padding-left: 19px;
  background: url(/images/icons/relic-storm.png) no-repeat left center;
}
.socket-relic-holy {
  padding-left: 19px;
  background: url(/images/icons/relic-holy.png) no-repeat left center;
}
.wowhead-newsfeed {
  background: #181818;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #ccc;
  padding: 10px;
  width: 276px;
  overflow: hidden;
  position: relative;
}
.wowhead-newsfeed .wowhead-header {
  margin: 0 0 10px;
}
.wowhead-newsfeed .wowhead-header a {
  color: #fff !important;
  padding: 0 0 0 21px;
  background: url(/images/logos/favicon-16.png) 0 center no-repeat !important;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none !important;
}
@media (-webkit-min-device-pixel-ratio: 1.25), (-webkit-min-device-pixel-ratio: 0.9375), (min-resolution: 90dpi) {
  .wowhead-newsfeed .wowhead-header a {
    background-image: url(/images/logos/favicon-32.png);
    background-size: 16px 16px;
  }
}
.wowhead-newsfeed .wowhead-cont {
  background: #242424;
  margin: 0 -10px -10px;
}
.wowhead-newsfeed .wowhead-cont a {
  border: 0 !important;
  color: #ffd100;
  text-decoration: none !important;
  display: block;
  outline: 0;
  padding: 10px;
  font: normal normal normal 13px / normal Arial, Helvetica, sans-serif;
}
.wowhead-newsfeed .wowhead-cont a:nth-child(even) {
  background: #202020;
}
.wowhead-newsfeed .wowhead-cont a:hover {
  background: #1c1c1c;
  color: #fff;
}
span.tooltip-pet-strongweak {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/images/wow/icons/battlepets/battlepetstrongweak.png);
}
hr.tooltip-pet-hr {
  background: #2d2928 !important;
  border: 0 !important;
  height: 3px !important;
  margin: 6px 0 !important;
  position: relative;
  z-index: 1;
}
td.tooltip-pet-header {
  padding-bottom: 4px !important;
}
div.tooltip-pettype-icon,
span.tooltip-pettype-icon {
  width: 39px;
  height: 39px;
  vertical-align: middle;
  display: inline-block;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
span.tooltip-pet-locationicons {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: -24%;
  margin-left: 3px;
}
span.tooltip-pet-locationicons-money {
  height: 13px;
  width: 13px;
  display: inline-block;
  vertical-align: -25%;
  margin-left: 3px;
}
span.tooltip-multiskill-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  background: url(/images/icons/multiskill.png) no-repeat left center;
}
span.tooltip-heroic-icon {
  padding-left: 13px;
  margin-left: 2px;
  background: url(/images/icons/heroic.gif) left center no-repeat;
  background-size: 14px 17px;
  vertical-align: middle;
}
.listmanager-mode1 {
  color: #00c2e1;
}
.listmanager-mode2 {
  color: #ebb800;
}

