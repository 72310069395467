.Icon {
  height: 56px;
  width: 56px;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
}

.selected {
  border: 1px outset #ff8000;
  box-shadow: inset 0 0 4px #ff8000;
}
