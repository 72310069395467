.Item {
  height: 56px;
  width: 56px;
  margin-bottom: 6px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  cursor: pointer;
}

.BisListFingers > .Item,
.BisListTrinkets > .Item,
.BissListBottom > .Item {
  margin-right: 6px;
}

.Item > div {
  float: left;
}
