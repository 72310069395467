@font-face {
  font-family: "FrizQuadrataBold";
  src: url("/font/FrizQuadrataBold.eot");
  src: url("/font/FrizQuadrataBold.eot?#iefix") format("embedded-opentype"),
    url("/font/FrizQuadrataBold.svg#FrizQuadrataBold") format("svg"),
    url("/font/FrizQuadrataBold.ttf") format("truetype"),
    url("/font/FrizQuadrataBold.woff") format("woff"),
    url("/font/FrizQuadrataBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

#videoslider {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.classicLogo {
  width: 20%;
  height: 20%;
}

.discordLogo {
  height: 25px;
  margin-bottom: 10px;
}

html {
  background-color: black;
  min-width: 600px;
}

body {
  background-color: black;
}
#root {
  max-width: 1400px;
  margin: 0 auto;
}

.App {
  font-family: FrizQuadrataBold;
  text-align: center;
  background-color: black;
  color: white;
}

.BisListFingers > div,
.BisListTrinkets > div,
.BisListLeft,
.BisListCenter,
.BisListRight,
.BissListBottom > div,
.row {
  display: inline-block;
}
.BisListFingers,
.BisListTrinkets {
  max-height: 62px;
}

.BisListCenter {
  padding: 5px;
  width: 320px;
  visibility: visible;
  vertical-align: top;
}

.Banner {
  padding: 10px;
}

.Footer {
  font-family: "Helvetica";
}

.qe0 {
  border: 1px outset #9d9d9d;
  box-shadow: inset 0 0 4px #9d9d9d;
}
.qe1 {
  border: 1px outset #fff;
  box-shadow: inset 0 0 4px #fff;
}
.qe2 {
  border: 1px outset #1eff00;
  box-shadow: inset 0 0 4px #1eff00;
}
.qe3 {
  border: 1px outset #0070ff;
  box-shadow: inset 0 0 4px #0070ff;
}
.qe4 {
  border: 1px outset #a335ee;
  box-shadow: inset 0 0 4px #a335ee;
}
.qe5 {
  border: 1px outset #ff8000;
  box-shadow: inset 0 0 4px #ff8000;
}

.tooltip-visible {
  visibility: visible !important;
}
